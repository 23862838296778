import React from 'react'

const NoPage = () => {
  return <div>NoPage</div>
};

export default NoPage;



